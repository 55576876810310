import { useEffect, useState } from "react";
import { connectWallet, getCurrentWalletConnected, getAllegianceStakes} from "./utils/interact.js";
import { mintlocalNFT, getMetaData, checkforOG } from "./utils/interact_test.js";


const StatBoard = (props) => {
  let stat_obj = props.userstats;
  if (Object.keys(stat_obj).length == 0) {
    stat_obj = {
      'woolSecondsNow': 0,
      'wool': 0,
      'totalTokenValueSeconds': 0,
      'woolcontribution': 0,
      'tokencontribution': 0,
      'overallcontribution': 0,
    };
  }
  const to_percent = (float) =>{
    return String((100*Number(float)).toFixed(3));
  };
  const wei2eth = (float) =>{
    return String((Number(float)/(10**(18))).toFixed(0));
  };
  return (
    <div className="userStats">
      <div className="statsFrame">
        <table >
          <tr>
            <th> Total contribution: </th> <td> {to_percent(stat_obj.overallcontribution) + " %" } </td>
          </tr>
          <tr>
            <th> Token contribution: </th> <td> {to_percent(stat_obj.tokencontribution) + " %" } </td>
          </tr>
          <tr>
            <th> Wool contribution: </th> <td> {to_percent(stat_obj.woolcontribution) + " %" } </td>
          </tr>
          <tr>
            <th> Total wool staked:  </th> <td> {wei2eth(stat_obj.wool) + " $WOOL" } </td>
          </tr>
        </table>
      </div>
    </div>
  )
}

const Gallery = (props) => {
  const tokens = props.tokenInfo;
  return (
    tokens.map((item) => {
      return (
        <div class="woolfFrame">
        <img src={item.imgsrc}
            width="100%" height="100%">
        </img>
        <div class="woolfCaption brokenconsolefont">
            {item.number}
        </div>
        </div>
      );
    })
  );
};


const Gallery2 = (props) => {
  const tokens = props.tokenInfo;
  if (tokens){
    return (
      tokens.map((item) => {
        return (
          <div class="woolfFrame2">
            <img src={item.imgsrc}
                width="100%" height="100%" alt="no image found.">
            </img>
            <div class="woolfCaption2 brokenconsolefont">
                {item.number}
            </div>
          </div>
        );
      })
    );
  }else{
    return (
          <div class="woolfFrame2">
            <div class="woolfCaption2 brokenconsolefont">
            </div>
          </div>
    );
  }

};

const Gallery3 = (props) => {
  const tokens = props.tokenInfo;
  if (tokens){
    return (
      tokens.map((item) => {
        return (
          <div class="woolfFrame3">
            <img src={item.imgsrc}
                width="100%" height="100%" alt="no image found.">
            </img>
            <div class="woolfCaption2 brokenconsolefont">
                {item.number}
            </div>
          </div>
        );
      })
    );
  }else{
    return (
          <div class="woolfFrame3">
            <div class="woolfCaption2 brokenconsolefont">
            </div>
          </div>
    );
  }

};

const TestBox = (props) => {
  //State variables
  let og_id = props.og;
  let text_custom = "";
  if (og_id){
    text_custom = "You earned a medal:"
    og_id = [og_id];
  }
  const [_tokenlist, set_Tokenlist] = useState([]);
  // input is as a JSON embedded list of numbers
  // { "x": [0,1,2,3,...,n]}
  const [_pack, set_Pack] =useState("");
  const [_medalId, set_MedalId] = useState("");
  const [_medalMeta, set_MedalMeta] = useState(false);

  const onMedalViewPressed = async () => {
    if (_medalId.length >0) {
      const number = Number(_medalId);
      console.log("tokenId", number);
      const {success, status, result} = await getMetaData(number);
      console.log(status);
      console.log("result is", result);
      if (success) {
        set_MedalMeta([result]);
      }else{
        set_MedalMeta(false);
      }
    }
    else{
      console.log("Enter valid tokenNumber");
    }
  };

  const onChangeMedalId = async (event) => {
    set_MedalId(event.target.value);
  };


  const colstyle = {"width":"50%", "display":"inline-block"};
  // const rowstyle2 = {"width":"60%","display":"flex","text-center":"center", "justify-content":"center"};
  const rowstyle = {"display":"flex", "align-items":"center", "padding": "0px 0px 10px 0px"};
  const btnfloat = {"margin":"0px 5px 0px 5px"};
  const inputfloat = {"text-align": 'center', "margin-left":"auto","width":"20%"};
  return (
    <div className="testContainer">
      <div style={colstyle}>
        <div style={rowstyle}>
            <h3 style={{"display":"inline-block", 'margin':"0px 10px 0px 0px", 'width':'60%',"text-align":'center'}}> Enter Medal #</h3>
          <input
            type="text"
            placeholder=""
            onChange={onChangeMedalId}
            style={inputfloat}
          />
          <button id="viewButton" onClick={onMedalViewPressed} style={btnfloat}>
            View
          </button>
        </div>
        <div class="woolfGallery2">
          <Gallery2 tokenInfo={_medalMeta}>        
          </Gallery2>
        </div> 
      </div>
      <div style={colstyle}>
      {/* <div style={rowstyle2}> */}
        <h3 style={{"margin":"auto", 'min-height': '25px', 'width':'100%',"text-align":'center', "padding": "0px 0px 15px 0px"}}> {text_custom}</h3>
      {/* </div> */}
        <div class="woolfGallery2">
          <Gallery3 tokenInfo={og_id}>        
          </Gallery3>
        </div> 
      </div>
    </div>
  )
};

const Minter = (props) => {

  //State variables
  const [og_id, setOG] = useState(false);
  const [walletAddress, setWallet] = useState("");
  const [status, setStatus] = useState("");
  const [pack, setPack] = useState("");
  const [tokensdata, setTokensdata] = useState([]);
  const [userstats, setUserStats] = useState({});

  useEffect(async () => {
    async function getCWC(){
      const {address, status} = await getCurrentWalletConnected();
      setWallet(address)
      setStatus(status);
    }
    getCWC();
    addWalletListener(); 
}, []);

  useEffect(async () => {
    async function uTL(){
      await updateTokenList();
    }
    uTL();
  }, [walletAddress, pack]);

const updateTokenList = async () => {
  if ((walletAddress.length > 0) && (pack.length > 0)) {
    const {tokenlist, stats} = await getAllegianceStakes(String(walletAddress), String(pack));
    console.log(stats);
    setTokensdata(tokenlist);
    setUserStats(stats);
    const {result, status, success} = await checkforOG(pack);
    // console.log(result);
    if (success==true){
      setOG(result);
      setStatus("👁 🎖 .");
    }else{
      setOG(false);
      setStatus("👁 🤷‍♂️ .");
    }
  } else {
    setTokensdata([]);
  }
}
function addWalletListener() {
  if (window.ethereum) {
    window.ethereum.on("accountsChanged", async (accounts) => {
      if (accounts.length > 0) {
        setWallet(accounts[0]);
        setStatus("👁 🔄.");
      } else {
        setWallet("");
        setStatus("👁 🦊. Connect to Metamask using the top right button.");
      }
    });
  } else {
    setStatus(
      <p>
        {" "}
        👁🦊{" "}
        <a target="_blank" rel="noreferrer" href={`https://metamask.io/download.html`}>
          You must install Metamask, a virtual Ethereum wallet, in your
          browser.
        </a>
      </p>
    );
  }
}
  const checkAnyContribution = () => {
    if (Object.keys(userstats).length > 0) {
      if (userstats.overallcontribution > 0) {
        console.log("contribution" , userstats.overallcontribution);
        return true;
      }
    }
    return false;
  }

  const connectWalletPressed = async () => {
    const walletResponse = await connectWallet();
    setStatus(walletResponse.status);
    setWallet(walletResponse.address);
  };

  const onMintPressed = async () => {
    if (checkAnyContribution()){
      setStatus("👁 🚀");
      const { status } = await mintlocalNFT(tokensdata, pack);
      setStatus(status);
    } else {
      setStatus("👁 🤌 : You have not contributed to this pack.");
    }
};

  const onPackChange = async (event) => {
    setPack(event.target.value);
    setStatus("👁 🛂.");
  };
  const rowstyle = {"display":"flex", "align-items":"center", "padding": "0px 0px 5px 0px"};

  return (
    <div className="Minter">
      <button id="walletButton" onClick={connectWalletPressed}>
        {walletAddress.length > 0 ? (
          "Connected: " +
          String(walletAddress).substring(0, 6) +
          "..." +
          String(walletAddress).substring(38)
        ) : (
          <span>Connect Wallet</span>
        )}
      </button>
      <br></br>
      <h1 id="title">🐺 👑 Mint Alpha Game Medals</h1>
      <div style={rowstyle}>
        <div style={{"width":"50%", "margin-right":"auto"}}>
          <p style={{"text-align": "center"}}>
            Select your pack and press "Mint Medal".
          </p>
        </div>
      <div style={{"padding": "0px 15px 0px 12px"}}>
        <select value ={pack} onChange={onPackChange}>
              <option value=""></option>
              <option value="2773"> 2773 </option>
              <option value="10540"> 10540 </option>
              <option value="4775"> 4775 </option>
              <option value="12890"> 12890 </option>
              <option value="10930"> 10930 </option>
              <option value="5047"> 5047 </option>
              <option value="11072"> 11072 </option>
              <option value="1701"> 1701 </option>
              <option value="993"> 993 </option>
              <option value="11833"> 11833 </option>
              <option value="6294"> 6294 </option>
              <option value="1026"> 1026 </option>
              <option value="12641"> 12641 </option>
        </select>
      </div>
      <div style={{"padding": "0px 12px 0px 15px"}}>
        <button id="mintButton2" onClick={onMintPressed}>
          Mint Medal
        </button>
      </div>
      </div>

      <div class="woolfGallery">
      <Gallery tokenInfo={tokensdata}>        
      </Gallery>
      </div>
      <StatBoard userstats={userstats}>
      </StatBoard>
      <p id="status" style={{"padding": "0px 20px 0px 20px"}}>
          {status}
      </p>
    <TestBox og={og_id}>
    </TestBox>
    </div>
  );
};

export default Minter;
