require('dotenv').config();

const alchemyKey = process.env.REACT_APP_ALCHEMY_KEY;
const { createAlchemyWeb3 } = require("@alch/alchemy-web3");
const web3 = createAlchemyWeb3(alchemyKey);
const parseDataUri = require('parse-data-uri');
const { labelToName, decode } = require("whatwg-encoding");


const localcontractABI = require('../localcontract-abi.json');
const localcontractAddress = process.env.REACT_APP_CONTRACT_LOCAL_ADDRESS;

export const getMetaData = async(tokenId) => {
    try {
        let localcontract_read = await new web3.eth.Contract(localcontractABI.abi, localcontractAddress);
        try{
            let datauri = "";
            // console.log(window.ethereum.selectedAddress);
            const call_Parameters2 = {
                to: localcontractAddress, // Required except during contract publications.
                from: window.ethereum.selectedAddress, // must match user's active address.
                'data': localcontract_read.methods.tokenURI(tokenId).encodeABI() //make call to NFT smart contract 
            }; 
            try{
                const datauri_enc = await window.ethereum
                    .request({
                        method: 'eth_call',
                        params: [call_Parameters2, "latest"],
                    });
                datauri = web3.eth.abi.decodeParameter('string', datauri_enc);
            }
            catch(error){
                console.log('eth_call issue', error);
            }
            // console.log(`datauri: ${datauri}`);

            const data_json = parseDataUri(datauri);
            const encodingName = labelToName("utf-8");
            const data = decode(data_json.data, encodingName);
            const obj_meta = JSON.parse(data);
            // console.log(obj_meta.image);
            const tokenInfo = {
                'number': tokenId,
                'attributes': obj_meta.attributes,
                'name': obj_meta.name,
                'imgsrc': obj_meta.image
            };
            return {
                success: true,
                status: "MetaData retrieved",
                result: tokenInfo
            }
        }
        catch(err){
            console.log(err);
            return {
                success: false,
                status: err.message,
                result:{}
            }
        }

    } catch(err){
        console.log(err);
        return {
            success: false,
            status: err.message,
            result:{}
        }
    }
};

export const mintlocalNFT = async(tokenlist, pack) => {
    //error handling
        try {
            console.log("tokenlist and pack");

            const numbers = tokenlist.map((entry) => {return Number(entry.number);});
            const packInt = Number(pack);
            console.log(numbers);
            console.log(packInt);

            window.localcontract = await new web3.eth.Contract(localcontractABI.abi, localcontractAddress);
            //set up your Ethereum transaction
            const transactionParameters = {
                to: localcontractAddress, // Required except during contract publications.
                from: window.ethereum.selectedAddress, // must match user's active address.
                'data': window.localcontract.methods.mint(numbers, packInt).encodeABI()//make call to NFT smart contract 
            }; 
            //sign the transaction via Metamask
            try {
            const txHash = await window.ethereum
                .request({
                    method: 'eth_sendTransaction',
                    params: [transactionParameters],
                });
            return {
                success: true,
                status: "👁 🎉 ✅ : Check out your transaction on the chain " + txHash
            }
            } catch (error) {
            return {
                success: false,
                status: "👁 🚷 : Something went wrong: " + error.message
            }
        
            }

        } catch(error){
            console.log(error);
            return {
                success: false,
                status: "👁 🚷 : Something went wrong: " + error.message
            }
        }

    };

export const checkforOG = async(pack) => {
    //error handling
        try {
            console.log("Check for OG");
            const packInt = Number(pack);
            // console.log(packInt);

            window.localcontract = await new web3.eth.Contract(localcontractABI.abi, localcontractAddress);
            //set up your Ethereum transaction
            const transactionParameters = {
                to: localcontractAddress, // Required except during contract publications.
                from: window.ethereum.selectedAddress, // must match user's active address.
                'data': window.localcontract.methods.MedalRecord( window.ethereum.selectedAddress, packInt).encodeABI()//make call to NFT smart contract 
            }; 
            //sign the transaction via Metamask
            try {
            const response = await window.ethereum
                .request({
                    method: 'eth_call',
                    params: [transactionParameters, "latest"],
                });
                const medalid = web3.eth.abi.decodeParameter('uint16', response);
                if (medalid >0){
                    return await getMetaData(medalid);
                }else{
                    return {
                        success: false,
                        result: {},
                        status: "👁 ❌ : Something went wrong during the query "
                    };
                }

            } catch (error) {
                console.log(error);
                return {
                    success: false,
                    result: {},
                    status: "👁 ❌ : Something went wrong during the query " + error.message
                };
            }

        } catch(error){
            console.log(error);
            return {
                success: false,
                result: {},
                status: "👁 ❌ : Something went wrong during the query " + error.message
            };
        }

    };