require('dotenv').config();
// Example POST method implementation:
const axios = require('axios');
const wg_subgraph_URL = process.env.REACT_APP_GRAPHURL;

const parseDataUri = require('parse-data-uri');
const { labelToName, decode } = require("whatwg-encoding");


export const connectWallet = async () => {
    if (window.ethereum) {
      try {
        const addressArray = await window.ethereum.request({
          method: "eth_requestAccounts",
        });
        const obj = {
          status: "👁 🗄 . ",
          address: addressArray[0],
        };
        return obj;
      } catch (err) {
        return {
          address: "",
          status: "👁 🤷‍♀️ : " + err.message,
        };
      }
    } else {
      return {
        address: "",
        status: (
          <span>
            <p>
              {" "}
              👁 🦊{" "}
              <a target="_blank" rel="noreferrer" href={`https://metamask.io/download.html`}>
                You must install Metamask, a virtual Ethereum wallet, in your
                browser.
              </a>
            </p>
          </span>
        ),
      };
    }
  };

export const getCurrentWalletConnected = async () => {
    if (window.ethereum) {
      try {
        const addressArray = await window.ethereum.request({
          method: "eth_accounts",
        });
        if (addressArray.length > 0) {
          return {
            address: addressArray[0],
            status: "👁 🗄 .",
          };
        } else {
          return {
            address: "",
            status: "👁 🦊: Connect to Metamask using the top right button.",
          };
        }
      } catch (err) {
        return {
          address: "",
          status: "👁 🤷‍♀️  " + err.message,
        };
      }
    } else {
      return {
        address: "",
        status: (
          <span>
            <p>
              {" "}
              🦊{" "}
              <a target="_blank" rel="noreferrer" href={`https://metamask.io/download.html`}>
                You must install Metamask, a virtual Ethereum wallet, in your
                browser.
              </a>
            </p>
          </span>
        ),
      };
    }
  };

export const getAllegianceStakes = async (address, pack) => {
  return axios.post(wg_subgraph_URL,{
        query: `{
          allegianceTokenStakes(
            where: {owner: "${address}", pack: "${pack}"}
          ) {
            token {
              source {
                number
                tokenURI
                isSheep
                alpha
              }
            }
            pack
            lastUpdated
          }
          allegianceWoolStakes(
            where: {owner: "${address}", pack: "${pack}"}
          ) {
            pack
            wool
            woolSeconds
            id
            lastUpdated
          }
          allegiancePacks(where: {id: "0x${Number(pack).toString(16)}"}) {
            balance
            tokenValueSeconds
            tokenValue
            woolSeconds
            wool
            lastUpdated
          }
        }`
      // query: `{
      //     allegianceTokenStakes(
      //       where: {owner: "${address}", pack: "${pack}"}
      //     ) {
      //         token {
      //             source {
      //                 number
      //                 tokenURI
      //             }
      //         }
      //         pack
      //     }
      //   }`
  })
  .then((res) => {
      const queryTokens = res.data.data.allegianceTokenStakes;
      // console.log('Total number of Tokens', query.length);
      // let aa = query.map((entry) => { return entry.token.source.number;});
      const nowTimestamp = Math.floor(Date.now() / 1000);
      let tokenlist = new Array();
      const woolfValues = [0, 0, 0, 0, 0, 5**2, 6**2, 7**2, 0];
      let totalTokenValueSeconds_Now = 0;
      for (const tokenstake of queryTokens) {
          const tokenID = tokenstake.token.source.number;
          const data_json = parseDataUri(tokenstake.token.source.tokenURI);
          const encodingName = labelToName("utf-8");
          const data = decode(data_json.data, encodingName);
          const obj_meta = JSON.parse(data);
          let tokenValueSeconds = 0;
          const elapsed = nowTimestamp-Number(tokenstake.lastUpdated);
          if (tokenstake.token.source.isSheep){
            tokenValueSeconds = 5*elapsed;
          }else{
            const alpha = tokenstake.token.source.alpha;
            tokenValueSeconds = (woolfValues[Number(alpha)])*elapsed;
          }
          totalTokenValueSeconds_Now +=tokenValueSeconds;
          const tokenInfo = {
              'number': tokenID,
              'attributes': obj_meta.attributes,
              'name': obj_meta.name,
              'imgsrc': obj_meta.image,
              'tokenValueSeconds': tokenValueSeconds
          };
          tokenlist.push(tokenInfo);
          // console.log(tokenstake.token.source.number);
          // console.log(`Token number ${tokenstake.token.source.number} staked in pack ${tokenstake.pack}`);
      }
      let queryWool = res.data.data.allegianceWoolStakes;
      if (queryWool.length == 0){
        queryWool = [{wool: 0, woolSeconds: 0, lastUpdated: 0}];
      }

      const {wool, woolSeconds, lastUpdated} = queryWool[0];
      // console.log(queryWool);

      const elapsed = (nowTimestamp-Number(lastUpdated));
      const woolSecondsNow = Number(wool)*elapsed + Number(woolSeconds);
      // const woolStake = {'pack': pack, 'woolSecondsNow': woolSecondsNow, 'wool': wool};

      let queryPack = res.data.data.allegiancePacks;
      // console.log(queryPack);
      if (queryPack.length == 0){
        queryPack = [{wool: 0, woolSeconds: 0, tokenValueSeconds: 0, tokenValue: 0, lastUpdated: 0}];
      }
      const {wool: wool_pack, woolSeconds: woolSeconds_pack, tokenValueSeconds: tokenValueSeconds_pack, tokenValue: tokenValue_pack, lastUpdated: lastUpdated_pack} = queryPack[0];
      const elapsed_pack = nowTimestamp-Number(lastUpdated_pack);
      const tokenValueSeconds_pack_now = Number(tokenValue_pack)*elapsed_pack + Number(tokenValueSeconds_pack);
      const woolSeconds_pack_now = Number(wool_pack)*elapsed_pack + Number(woolSeconds_pack);
      
      let contribution4pack = 0;
      let tokencontribution4pack = 0;
      let woolcontribution4pack = 0;
      if (woolSeconds_pack_now > 0){
        woolcontribution4pack = woolSecondsNow/woolSeconds_pack_now;
      } 

      if (tokenValueSeconds_pack_now>0) {
        tokencontribution4pack = totalTokenValueSeconds_Now/tokenValueSeconds_pack_now;
      } 

      contribution4pack = 0.5*(tokencontribution4pack + woolcontribution4pack);
      const stats = {
        'woolSecondsNow': woolSecondsNow,
        'wool': Number(wool),
        'totalTokenValueSeconds': totalTokenValueSeconds_Now,
        'woolcontribution': woolcontribution4pack,
        'tokencontribution': tokencontribution4pack,
        'overallcontribution': contribution4pack,
      }
      console.log(stats);
      return {'tokenlist':tokenlist, 'stats':stats};
  })
  .catch((error) => {
    console.error(error);
  });
};


